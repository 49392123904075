:root {
	--primary-color: #4699f7;
	--secondary-color: #e8f2ff;
}

.rdt_TableHeadRow {
	background-color: transparent !important;
	border: none !important;
	border-radius: 5px;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;
}

.rdt_TableHead {
	background-color: #e4e7ec !important;
	margin-bottom: 10px;
}

.rdt_TableRow {
	border-radius: 5px;
	margin-bottom: 8px;
	margin-top: 8px;
	border: none !important;
	background-color: white !important;
	color: #595858 !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 20px !important;
	height: 65px;
	overflow: scroll;
}

.exctr-select-input select {
	appearance: none;
	/* height: 48px; */
}

.exctr-select-input {
	position: relative;
}

.exctr-select-input:after {
	font: var(--fa-font-solid);
	content: "\f0d7";
	font-size: 12px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.school-logo {
	width: 100px;
	object-fit: contain;
	max-height: 150px;
}

.attachment-link {
	text-decoration: none;
}

.attachment {
	display: flex;
	align-items: center;
	gap: 10px;
	box-sizing: border-box;
	position: relative;
	border: 1px solid #e2e8f0;
	border-radius: 2px;
	padding: 12px 8px;
	width: fit-content;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #334155;
}

.attachment svg {
	width: 35px;
	height: 35px;
}

.attachment-image {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 10px;
}

.link-style {
	text-decoration: none;
	color: var(--primary-color);
}

.link-style:hover {
	text-decoration: underline;
	color: var(--primary-color);
}

/* pagenation */

.exam-pagenate {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 40px;
}

.pagenate-prev,
.pagenate-next {
	width: 36px;
	height: 36px;
	border: 0.5px solid #c4c4c4;
	box-sizing: border-box;

	background-color: #f5f7fb;
}

.pagenate-next {
	border-radius: 0px 4px 4px 0px;
}

.pagenate-prev {
	border-radius: 4px 0px 0px 4px;
	margin-right: 5px;
}

.pagenate-next svg,
.pagenate-prev svg {
	color: #4a4a4a;
	font-size: 20px;
	text-align: center;
}

.pagenate-next svg:hover,
.pagenate-prev svg:hover {
	cursor: not-allowed;
}

.pagenate-active:hover {
	background-color: var(--primary-color);
	cursor: pointer;
}

.pagenate-active:hover svg {
	background-color: var(--primary-color);
	color: white;
	cursor: pointer;
}

.pagenate-active svg {
	color: var(--primary-color);
}

.pagenate-input {
	width: 56px;
	height: 36px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	padding: 10px;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
}

.pagenate-text {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	padding: 10px 4px;
	margin: 0 10px;
	color: #4a4a4a;
}
