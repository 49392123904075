html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pri-action-btn,
.pri-action-btn:link,
.pri-action-btn:visited {
	text-decoration: none;
	text-transform: capitalize;
	display: inline-block;
	align-items: center;
}

.pri-action-btn {
	background-color: var(--primary-color) !important;
	border-radius: 8px !important;
	padding: 8px 25px;
	color: white !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center;
	border: 2px solid var(--primary-color) !important;
}

.pri-action-btn:hover {
	color: var(--primary-color) !important;
	border: 2px solid var(--primary-color) !important;
	background-color: transparent !important;
	transition: ease-in-out 300ms;
}

.editor-tooltip {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	padding: 10px;
	flex-direction: column;
	border: 1px solid black;
}

.rdw-editor-main {
	background-color: white;
	border: 1px lightgray solid;
	padding: 10px;
	min-height: 300px;
	resize: vertical;
	margin-bottom: 20px;
}

.rdw-editor-toolbar {
	background-color: transparent !important;
	border: 1px lightgray solid !important;
	padding: 10px;
}

.rdw-dropdown-selectedtext {
	display: flex;
	position: relative;
	height: 100%;
	align-items: center;
	padding: 0 5px;
	color: black;
}
